import ArrowIconWhite from '../../../images/icons/arrow-icon-btn.svg';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

function PrimaryButton({
  caption,
  urlLink,
  className,
  color,
  bgColor,
  onClick,
  type,
  fontSize,
  showArrowIcon,
  customerInfoLoading,
  accountSetupInfoLoading,
  disabled,
  isBorderRequired,
  style,
  fontWeight,
  fromCompanyCreationProductPage,
  redirectToSelectManager,
  target,
  id,
  parentClassName
}) {
  const getButtonComponent = () => {
    return (
      <button
        id={id}
        style={style}
        disabled={disabled}
        type="button"
        onClick={() => !customerInfoLoading && !accountSetupInfoLoading && onClick && onClick()}
        className={`${
          type
            ? isBorderRequired
              ? 'sign-up-button-without-border'
              : 'sign-up-button'
            : isBorderRequired
            ? 'buttonWithoutBorder'
            : 'button'
        } button-primary ${className} ${color} ${bgColor} ${fontSize} ${fontWeight}`}
      >
        <span>
          {showArrowIcon ? (
            <>
              <img src={ArrowIconWhite} className="mr-3" />
            </>
          ) : null}
        </span>
        {caption}
      </button>
    );
  };
  return (
    <>
      {urlLink ? (
        <Link
          style={{ textDecoration: 'none' }}
          to={urlLink}
          state={{
            fromCompanyCreationProductPage: fromCompanyCreationProductPage && fromCompanyCreationProductPage,
            redirectToSelectManager: redirectToSelectManager && redirectToSelectManager
          }}
          target={target}
          className={parentClassName}
        >
          {getButtonComponent()}
        </Link>
      ) : (
        <div style={{ textDecoration: 'none' }} className={parentClassName}>
          {getButtonComponent()}
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    customerInfoLoading: state?.customerInfo?.loading,
    accountSetupInfoLoading: state?.AccountSetupInfo?.loading
  };
};
export default connect(mapStateToProps, null)(PrimaryButton);

PrimaryButton.propTypes = {
  showArrowIcon: PropTypes.bool,
  caption: PropTypes.string,
  urlLink: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  fontSize: PropTypes.string,
  customerInfoLoading: PropTypes.bool,
  accountSetupInfoLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  isBorderRequired: PropTypes.bool,
  style: PropTypes.any,
  fontWeight: PropTypes.string,
  fromCompanyCreationProductPage: PropTypes.bool,
  target: PropTypes.string,
  id: PropTypes.string,
  redirectToSelectManager: PropTypes.bool,
  parentClassName: PropTypes.string
};

PrimaryButton.defaultProps = {
  showArrowIcon: false,
  color: 'text-white',
  bgColor: 'bg-coral-500',
  fontSize: 'text-sm md:text-lg text-xl',
  disabled: false,
  isBorderRequired: false,
  fromCompanyCreationProductPage: false,
  target: '_self',
  redirectToSelectManager: PropTypes.false
};
