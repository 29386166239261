import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import ArrowDown from '../../../images/icons/arrow-down-menu.svg';
import XMSText from './XMSText';

function MenuText({ title, urlLink, withDropDown, linkClassName, imgClassName, isNew, id }) {
  const [navLine, setNavLine] = useState(false);

  return (
    <div className={`${navLine ? 'active' : null} `}>
      {urlLink ? (
        <Link
          to={urlLink}
          className={`menu-text text-lg ${navLine ? 'active' : null} ${linkClassName}`}
          onMouseEnter={() => setNavLine(true)}
          onMouseLeave={() => setNavLine(false)}
          id={id}
        >
          {title}

          {withDropDown ? <img src={ArrowDown} className={imgClassName} alt="arrow down" /> : ''}
          {isNew && (
            <div className="bg-coral-500 rounded-lg ml-2">
              <XMSText title="New" textColor="text-white" className="px-2 py-1" />
            </div>
          )}
        </Link>
      ) : (
        <div
          className={`menu-text cursor-pointer text-lg ${navLine ? 'active' : null} ${linkClassName}`}
          onMouseEnter={() => setNavLine(true)}
          onMouseLeave={() => setNavLine(false)}
        >
          {title}

          {withDropDown ? <img src={ArrowDown} className={imgClassName} alt="arrow down" /> : ''}
          {isNew && (
            <div className="bg-coral-500 rounded-lg ml-2">
              <XMSText title="New" textColor="text-white" className="px-2 py-1" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MenuText;

MenuText.propTypes = {
  title: PropTypes.string,
  urlLink: PropTypes.string,
  withDropDown: PropTypes.bool,
  imgClassName: PropTypes.string,
  linkClassName: PropTypes.bool,
  isNew: PropTypes.bool,
  id: PropTypes.string
};

MenuText.defaultProps = {
  withDropDown: false
};
