import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/layout';
import PropTypes from 'prop-types';
import TableofContents from '../components/ui/TableofContents';
import BaseText from '../components/ui/typography/BaseText';
import './post.scss';
import { RichText } from 'prismic-reactjs';
import SEO from '../components/seo';
import FaqDropDown from '../components/ui/dropdowns/FaqDropDown';
import { MUI_COLORS, RICH_TEXT_TYPE, SCREEN_WIDTH, SLICE_TYPE } from '../constants/enum';
import PromoHeader from '../components/blog-template/PromoHeader';
import Header from '../components/blog-template/Header';
import PrimaryButton from '../components/ui/buttons/PrimaryButton';
import addCircle from '../images/icons/addCircle.svg';
import SubtractCircle from '../images/icons/subtractCircle.svg';
import ArticleCard from '../components/blog/ArticleCard';
import CustomRelatedArticleCard from '../components/blog/CustomRelatedArticleCard';
import Bullet from '../components/common/Bullet';
import XSText from '../components/ui/typography/XSText';
import { isBrowser, replaceSpaceAndSpecialCharacter, replaceWatchWithEmbed } from '../helper/helpers';
import FloatingButton from '../components/blog-template/FloatingButton';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import MobileToc from '../components/blog-template/MobileToc';
import Quote from '../components/blog-template/Quote';
import EditorNotes from '../components/blog-template/EditorNotes';
import Disclaimer from '../components/blog-template/Disclaimer';
import ImageSlice from '../components/blog/ImageSlice';
import LinkCta from '../components/blog/LinkCta';
import HighlightBox from '../components/ui/Slices/HighlightBox';
import CustomTable from '../components/ui/Slices/CustomTable';
import Headings from '../components/blog/Headings';
import Toggleable from '../components/ui/Slices/Toggleable';

function GuidesTemplate({ articleData, allPrismicArticleList }) {
  const tableRef = useRef([]);
  const sheetRef = useRef(null);
  const articleRef = useRef(null);
  const [headings, setHeadings] = useState([]);
  const [isTableInViewPort, setIsTableInViewPort] = useState(false);
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const [handleDropDown, setHandleDropDown] = useState(false);
  const [showMobileToc, setShowMobileToc] = useState(false);
  if (!articleData) {
    return null;
  }
  function addAttributesToBlogImages() {
    const blockImgClass = document.querySelectorAll('.block-img');
    for (let i = 0; i < blockImgClass.length; i++) {
      const blockImg = blockImgClass[i].querySelectorAll('img');
      if (!blockImg?.[0].alt) {
        blockImg?.[0].setAttribute('alt', articleData?.data?.title.text);
      }
      blockImg?.[0].setAttribute('loading', 'lazy');
    }
  }

  useEffect(() => {
    var parentElement = document.querySelectorAll('.blog-link');
    for (let index = 0; index < parentElement.length; index++) {
      const element = parentElement[index];
      const spanElement = element.querySelector('.nofollow');
      if (spanElement) {
        element.setAttribute('rel', 'nofollow');
      }
    }
    let matches = document.querySelectorAll('span.nofollow > a');
    for (let index = 0; index < matches.length; index++) {
      matches[index].setAttribute('rel', 'nofollow');
    }
    addAttributesToBlogImages();
  }, []);

  const getNestedHeadings = (headingElements) => {
    const nestedHeadings = [];
    headingElements?.forEach((heading) => {
      const { id, innerText: title } = heading;
      if (heading.nodeName === 'H2') {
        nestedHeadings.push({ id, title, items: [], level: Number(heading.nodeName?.charAt(1)) });
      } else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
        nestedHeadings[nestedHeadings.length - 1].items.push({
          parentId: nestedHeadings[nestedHeadings.length - 1].id,
          id,
          title,
          level: Number(heading.nodeName?.charAt(1))
        });
      }
    });
    return nestedHeadings;
  };

  useEffect(() => {
    const divArticle = document.querySelector('.article-content');
    const allHTags = divArticle.querySelectorAll('h2, h3');
    if (allHTags) {
      for (let i = 0; i < allHTags.length; i++) {
        allHTags[i].setAttribute('id', replaceSpaceAndSpecialCharacter(allHTags[i]?.innerText));
      }
    }
    const newheadingElements = Array.from(divArticle.querySelectorAll('h2, h3'));
    const newNestedHeadings = getNestedHeadings(newheadingElements);
    setHeadings(newNestedHeadings);
  }, []);

  const intersectorOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  };

  const checkIntersection = (refs) => {
    if (Array.isArray(refs) && refs.length) {
      const observer = new IntersectionObserver((entries) => {
        const [entry] = entries;
        const isTableFullWidth = entry?.target?.className === 'fullWidthTable' ? true : false;
        if (entry.isIntersecting === true && isTableFullWidth === true) {
          setIsTableInViewPort(true);
        } else {
          if (entry?.target?.className === 'fullWidthTable') {
            if (entry?.intersectionRatio === 0) {
              setIsTableInViewPort(false);
            }
          }
        }
        return entry.isIntersecting;
      }, intersectorOptions);
      refs.forEach((ref) => {
        observer.observe(ref);
      });
    }
  };

  useEffect(() => {
    const tableTags = document.querySelectorAll('table');
    if (tableTags) {
      [...tableTags].forEach((table, index) => {
        tableRef.current[index] = table;
      });
    }
    checkIntersection(tableRef.current);
  }, []);

  useEffect(() => {
    const tocH3Heading = document.querySelectorAll('.toc-h3-heading');
    for (let i = 0; i < tocH3Heading?.length; i++) {
      const liOfToc = tocH3Heading[i].querySelectorAll('p');
      if (liOfToc[0].innerHTML === '') {
        liOfToc[0].classList.add('hidden');
      }
    }
  }, [headings]);

  function articles(label, sideIcon, backgroundColor) {
    const mainElement = document.getElementsByClassName('article-content')[0];
    const target = mainElement.innerHTML;
    const checkingNoFollow = document.getElementsByClassName('nofollow');
    if (!checkingNoFollow) {
      mainElement.innerHTML = target.replace(/(<span)/gim, '<div').replace(/<\/span>/gim, '</div>');
    }
    const containerClass = document.getElementsByClassName(label);
    for (let i = 0; i < containerClass.length; i++) {
      const icon = sideIcon && document.createElement('img');
      if (sideIcon) {
        icon.src = sideIcon;
        icon.className = 'richTextIcon';
        icon.style.alignSelf = 'flex-start';
        icon.style.width = '32px';
        icon.style.margin = '0px';
      }

      const anchorTag = containerClass[0].getElementsByTagName('a')[0];
      if (anchorTag) {
        anchorTag.className = 'text-bold';
        anchorTag.style.textDecoration = 'none';
      }

      const containerForTextAndLink = document.createElement('div');
      icon && containerForTextAndLink.appendChild(icon.cloneNode(true));
      containerForTextAndLink.appendChild(containerClass[i].cloneNode(true));
      containerClass[i].parentNode.replaceChild(containerForTextAndLink, containerClass[i]);
      containerClass[i].style.paddingLeft = sideIcon && '24px';
      containerClass[i].style.marginBottom = '0px';
      containerClass[i].style.color = '#464646';
      containerForTextAndLink.className = 'containerOfRichText';

      containerForTextAndLink.style.backgroundColor = backgroundColor;
      containerForTextAndLink.style.padding = backgroundColor === MUI_COLORS.PINK ? '24px 40px' : '24px';
      containerForTextAndLink.style.borderRadius = '8px';
      containerForTextAndLink.style.fontFamily =
        backgroundColor === MUI_COLORS.PINK ? 'HKGrotesk-bold' : 'HKGrotesk-Regular';
      containerForTextAndLink.style.fontSize = '18px';
      containerForTextAndLink.style.textAlign = backgroundColor === MUI_COLORS.PINK ? 'center' : 'left';
      containerForTextAndLink.style.lineHeight = '20px';
      containerForTextAndLink.style.display = 'flex';
      containerForTextAndLink.style.marginTop = '1rem';
    }
  }

  useEffect(() => {
    articles(RICH_TEXT_TYPE.PURPLE, null, MUI_COLORS.LIGHT_CREAM);
    articles(RICH_TEXT_TYPE.GREEN, null, MUI_COLORS.LIGHT_GREEN);
    articles(RICH_TEXT_TYPE.YELLOW, null, MUI_COLORS.LIGHT_YELLOW);
    articles(RICH_TEXT_TYPE.GRAY, null, MUI_COLORS.LIGHT_BLUE);
    articles(RICH_TEXT_TYPE.PINK, null, MUI_COLORS.PINK);
    scrollToSource();
  }, []);

  useEffect(() => {
    const intersectorOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };
    let observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry?.intersectionRatio > 0) {
        setShowMobileToc(true);
      } else {
        setShowMobileToc(false);
      }
    }, intersectorOptions);
    observer.observe(articleRef.current);
    return () => {
      if (articleRef.current) observer.unobserve(articleRef.current);
    };
  }, [articleRef]);

  const post = articleData?.data;
  let blogHeaderColor = {
    backgroundColor: post?.blog_header_background_color,
    textColor: post?.blog_header_text_color
  };

  let allPostsList = allPrismicArticleList?.nodes;

  let categoryBlogs = allPrismicArticleList?.nodes;
  let breadCrumbData = [
    {
      position: '1',
      name: 'Guides',
      item: process.env.SITEURL + '/guides'
    },
    {
      position: '2',
      name: post?.linked_blog?.document?.data?.title?.text
        ? post?.linked_blog?.document?.data?.title?.text
        : post?.silos_category?.document?.data?.title?.text,
      item:
        process.env.SITEURL +
        `/guides/${
          post?.linked_blog?.document?.uid ? post?.linked_blog?.document?.uid : post?.silos_category?.document?.uid
        }`
    },
    {
      position: '3',
      name: post?.section_category?.document?.data?.subtitle?.text
        ? post?.section_category?.document?.data?.subtitle?.text
        : post?.section_category?.document?.data?.title?.text,
      item:
        process.env.SITEURL +
        `/guides/${
          post?.section_category?.document?.data?.custom_url?.text
            ? post?.section_category?.document?.data?.custom_url?.text
            : post?.section_category?.document?.uid
        }`
    }
  ];
  !post?.section_category?.document?.data?.title?.text && breadCrumbData.pop();
  const articleSchemaData = {
    datePublished: post?.published_date,
    dateModified: articleData?.last_publication_date,
    title: post?.title?.text,
    readingTime: post?.time_to_read?.text,
    author: [
      {
        '@type': 'Person',
        name: post?.author?.document?.data?.full_name?.text,
        url: `${process.env.SITEURL}/team/${post?.author?.document?.uid}`
      }
    ]
  };
  const blogHeaderSelection = () => {
    if (post?.show_promo_header) return <PromoHeader post={post} />;
    else
      return (
        <Header
          type="guides"
          post={post}
          allPostsList={allPostsList}
          blogUid={articleData?.uid}
          colors={blogHeaderColor}
          publishDate={post?.published_date}
        />
      );
  };

  function scrollToSource() {
    const sourcesElement = document.getElementsByClassName('source');
    for (let i = 0; i < sourcesElement.length; i++) {
      const anchorTagForSources = document.createElement('a');
      anchorTagForSources.appendChild(sourcesElement[i].cloneNode(true));
      sourcesElement[i].parentNode.replaceChild(anchorTagForSources, sourcesElement[i]);
      sourcesElement[i].innerHTML = `[${i + 1}]`;
      anchorTagForSources.href = '#sources';
      anchorTagForSources.style.cursor = 'pointer';
    }
  }

  const showTocSheet = () => {
    setIsDialogueOpen(true);
  };
  const closeTocSheet = () => {
    setIsDialogueOpen(false);
  };

  return (
    <Layout
      fullwidth={true}
      blog={true}
      blogPost={true}
      showCustomerReviewsFooter={false}
      bannerData={post?.link_banner?.document?.data}
      pageClassName="bg-customBg-whitesmoke pillar-page md:px-0 first-section"
    >
      <SEO
        description={post?.seo_description?.text}
        keywords={post?.seo_focus_keywords.text ? post?.seo_focus_keywords?.text.split(',') : []}
        title={post?.seo_title?.text}
        metaImage={post?.meta_image?.url}
        preLoadHeaderImage={post?.header_image.url}
        type="blog"
        faqData={post?.seo_faq}
        breadCrumbData={breadCrumbData}
        articleSchemaData={articleSchemaData}
        videoSchema={post?.video_schema?.text && JSON?.parse(post?.video_schema?.text)}
      />
      {showMobileToc && isBrowser && window.innerWidth <= SCREEN_WIDTH?.SM && (
        <>
          <FloatingButton onClick={showTocSheet} />
          <BottomSheet
            open={isDialogueOpen}
            ref={sheetRef}
            snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight - 230]}
          >
            <MobileToc tocs={headings} onClick={closeTocSheet} />
          </BottomSheet>
        </>
      )}
      {blogHeaderSelection()}
      <div
        className={`
            ${post?.show_toc ? 'md:gap-14 gap-6 justify-between' : 'justify-center'}
            blog  article-content flex md:flex-row pt-12 flex-col-reverse blog-header-container mx-auto`}
        ref={articleRef}
      >
        <article className="article-content">
          <div className="article-content-parent-container">
            <RichText
              render={post?.section_text?.richText}
              elements={{
                hyperlink: function a({ children, data }) {
                  return (
                    <a className="blog-link" target={data?.target} href={data?.url}>
                      {children}
                    </a>
                  );
                }
              }}
            />
            <RichText
              render={post?.content?.richText}
              elements={{
                hyperlink: function a({ children, data }) {
                  return (
                    <a className="blog-link" target={data?.target} href={data?.url}>
                      {children}
                    </a>
                  );
                }
              }}
            />
            {post.body.map((slice, index) => {
              if (slice?.slice_type === SLICE_TYPE.RICH_TEXT) {
                return (
                  <div className="mb-8 break-words" key={index}>
                    <div dangerouslySetInnerHTML={{ __html: slice?.primary?.page_content?.html }} />
                  </div>
                );
              }

              if (slice?.slice_type === SLICE_TYPE.IMAGE) {
                return <ImageSlice slice={slice} key={index} />;
              }
              if (slice?.slice_type === SLICE_TYPE?.LINK_CTA) {
                return <LinkCta slice={slice} key={index} />;
              }
              if (slice?.slice_type === SLICE_TYPE.BLOG_CTA) {
                const stringWithN = slice?.primary?.description1.text.split('/n');
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor: slice?.primary?.background_color
                        ? slice?.primary?.background_color
                        : MUI_COLORS.PINK,
                      backgroundImage:
                        slice?.primary?.background_image?.url && `url('${slice?.primary?.background_image?.url}')`,
                      backgroundPosition: 'center',
                      objectFit: 'cover'
                    }}
                    className="my-8 rounded-lg"
                  >
                    <div
                      className={`flex md:flex-row flex-col md:${slice?.primary?.cta_image?.url ? 'pl-8' : 'px-8'} ${
                        slice?.primary?.cta_image?.url ? 'px-auto' : ''
                      }  items-center justify-between`}
                    >
                      <div className={`w-10/12  ${!slice?.primary?.cta_image?.url ? 'mb-6' : 'mb-0'}`}>
                        <BaseText
                          title={slice?.primary?.title1?.text}
                          fontWeight="text-bold"
                          className="blog-cta-container mt-6"
                          style={{
                            color: slice?.primary?.text_color ? slice?.primary?.text_color : MUI_COLORS.CHARCOAL,
                            fontSize: slice?.primary?.title_font_size ? slice?.primary?.title_font_size : '32px',
                            lineHeight: '110%'
                          }}
                        />
                        <div className="mb-4">
                          {stringWithN?.map((description, index) => {
                            return (
                              <BaseText
                                key={index}
                                title={description.replace(/\n/g, '')}
                                className="whitespace-pre-line blog-cta-description"
                                style={{
                                  color: slice?.primary?.text_color ? slice?.primary?.text_color : MUI_COLORS.CHARCOAL
                                }}
                              />
                            );
                          })}
                        </div>
                        <div className="flex md:flex-row flex-col md:gap-4 gap-2">
                          <PrimaryButton
                            urlLink={slice?.primary?.button_url?.url}
                            caption={slice?.primary?.cta_button?.text}
                            className={`${slice?.primary?.cta_image?.url ? 'block' : 'hidden'} md:mb-6 mb-0 w-full`}
                            type="medium-button"
                            target="_blank"
                            isBorderRequired={true}
                            style={{
                              backgroundColor: slice?.primary?.button_color
                                ? slice?.primary?.button_color
                                : MUI_COLORS.CORAL,
                              color: slice?.primary?.button_text_color
                                ? slice?.primary?.button_text_color
                                : MUI_COLORS.WHITE,
                              height: 56
                            }}
                          />
                          {slice?.primary?.secondary_button?.text && (
                            <PrimaryButton
                              urlLink={slice?.primary?.secondary_button_url?.url}
                              caption={slice?.primary?.secondary_button?.text}
                              className={`${slice?.primary?.cta_image?.url ? 'block' : 'hidden'} md:mb-6 mb-0 w-full`}
                              type="medium-button"
                              target="_blank"
                              isBorderRequired={true}
                              style={{
                                backgroundColor: slice?.primary?.secondary_button_color
                                  ? slice?.primary?.secondary_button_color
                                  : MUI_COLORS.CORAL,
                                color: slice?.primary?.secondary_text_color
                                  ? slice?.primary?.secondary_text_color
                                  : MUI_COLORS.WHITE,
                                height: 56
                              }}
                            />
                          )}
                        </div>
                      </div>

                      {slice?.primary?.cta_image?.url && (
                        <img
                          src={slice?.primary?.cta_image?.url}
                          alt={slice?.primary?.cta_image?.alt}
                          className="blog-cta-image self-end"
                          loading="lazy"
                          width="340"
                          height="340"
                        />
                      )}

                      {!slice?.primary?.cta_image?.url && (
                        <PrimaryButton
                          urlLink={slice?.primary?.button_url?.url}
                          caption={slice?.primary?.cta_button?.text}
                          className="md:w-28 w-full md:mb-0 mb-4"
                          type="medium-button"
                          target="_blank"
                          isBorderRequired={true}
                          style={{
                            backgroundColor: slice?.primary?.button_color
                              ? slice?.primary?.button_color
                              : MUI_COLORS.CORAL,
                            color: slice?.primary?.button_text_color
                              ? slice?.primary?.button_text_color
                              : MUI_COLORS.WHITE
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              }
              if (slice?.slice_type === SLICE_TYPE.TABLE) {
                return (
                  <div className="my-8" key={index}>
                    <div
                      id={slice?.primary?.is_table_full_width && 'blog-table-full-width'}
                      className={`${' blog-table '}${post?.show_competitors_vs_header && 'competitor-blog-table'} `}
                      dangerouslySetInnerHTML={{ __html: slice?.primary?.table_data?.text }}
                    />
                  </div>
                );
              }
              if (slice?.slice_type === SLICE_TYPE.EDITORS_NOTE) {
                return <EditorNotes key={index} post={post?.is_report_article_blog} slice={slice} />;
              }
              if (slice?.slice_type === SLICE_TYPE.PODCAST) {
                return <div id={slice?.primary?.podcast_id?.text} key={index} className="my-4" />;
              }
              if (slice?.slice_type === 'button') {
                return (
                  <div className="text-center my-8" key={index}>
                    <button
                      type="button"
                      className="bg-coral-500 text-white text-base p-4 rounded-md font-bold text-center slice-button"
                    >
                      <a
                        href={slice?.primary?.button_link?.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        {slice?.primary?.button_title?.text}
                      </a>
                    </button>
                  </div>
                );
              }
              if (slice?.slice_type === SLICE_TYPE.YOUTUBE_VIDEO) {
                return (
                  <div className="my-8 flex justify-center youtube-slice-container" key={index}>
                    <iframe
                      loading="lazy"
                      src={replaceWatchWithEmbed(slice.primary.youtube_link.url)}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                );
              }
              if (slice?.slice_type === SLICE_TYPE.KEY_TAKEAWAYS) {
                return (
                  <div
                    className={`bg-white rounded-lg mb-8 ${
                      post?.is_report_article_blog && 'padding-for-report-article mx-auto'
                    }`}
                    key={index}
                  >
                    <div className="py-8 px-8">
                      <BaseText
                        title={slice?.primary?.key_takaways_title?.text}
                        fontWeight="text-bold"
                        textColor="text-gray-500"
                      />

                      {slice?.items?.map((takeAwayPoints, index) => {
                        return (
                          <>
                            <div key={index} className={`flex flex-row items-start gap-2`}>
                              {slice?.primary?.list_type ? (
                                <XSText
                                  title={`${index + 1}.`}
                                  className="takeaways-points"
                                  textColor="text-gray-150"
                                  fontWeight="text-bold"
                                />
                              ) : (
                                <div className="mt-2">
                                  <Bullet />
                                </div>
                              )}
                              {takeAwayPoints?.takeaway_point_link?.url ? (
                                <a
                                  href={takeAwayPoints?.takeaway_point_link?.url}
                                  target={
                                    takeAwayPoints?.takeaway_point_link?.target &&
                                    takeAwayPoints?.takeaway_point_link?.target
                                  }
                                  alt="take away points"
                                  className={` ${
                                    slice?.primary?.list_type && 'margin-link'
                                  } takeaways-points-with-url mb-2`}
                                >
                                  {takeAwayPoints?.takeaways_points?.text}
                                </a>
                              ) : (
                                <XSText
                                  title={takeAwayPoints?.takeaways_points?.text}
                                  className="takeaways-points"
                                  textColor="text-gray-150"
                                />
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                );
              }
              if (slice?.slice_type === SLICE_TYPE.QUOTE) {
                return (
                  <Quote
                    key={index}
                    quoteItems={slice?.items}
                    quoteData={slice?.primary}
                    className={post?.is_report_article_blog && 'margin-for-report-article mx-auto'}
                  />
                );
              }
              if (slice?.slice_type === SLICE_TYPE.DOUBLE_COLUMN) {
                return (
                  <div className="flex md:flex-row flex-col md:gap-8 gap-4 mt-8 double-column-container" key={index}>
                    <div className="w-full" dangerouslySetInnerHTML={{ __html: slice?.primary?.first_column?.html }} />
                    <div className="w-full" dangerouslySetInnerHTML={{ __html: slice?.primary?.second_column?.html }} />
                  </div>
                );
              }
              if (slice?.slice_type === SLICE_TYPE.HIGHLIGHT_BOX) {
                return <HighlightBox key={index} highlightBoxData={slice?.primary} />;
              }
              if (slice?.slice_type === SLICE_TYPE.TOGGLEABLE) {
                return <Toggleable key={index} data={slice?.primary} />;
              }
              if (slice?.slice_type === SLICE_TYPE.CUSTOM_TABLE) {
                return <CustomTable slice={slice} key={index} />;
              }
              if (slice?.slice_type === SLICE_TYPE.HEADING) {
                return <Headings data={slice} key={index} />;
              }
              return null;
            })}
          </div>

          <div className={`w-full mb-12`}>
            {post?.seo_faq[0]?.question?.text ? (
              <div>
                <h2>FAQs</h2>
                {post?.seo_faq.map((data, index) => {
                  return (
                    <div key={index}>
                      <FaqDropDown blog={true} open={index === 0 ? true : false} data={data} />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          {post?.sources[0]?.sources_list?.text && (
            <div id="sources" className="sources">
              <h2>Sources</h2>
              <div
                className={`
                      ${
                        handleDropDown
                          ? 'p-6 transition-all duration-500'
                          : 'px-6 pt-6 pb-6 transition-all duration-500'
                      } rounded-lg bg-white mb-4
                    `}
              >
                <div className="flex justify-between cursor-pointer" onClick={() => setHandleDropDown(!handleDropDown)}>
                  <ul className="md:w-11/12 w-10/12 source-ul">
                    {post.sources.map((list, index, row) => {
                      if (handleDropDown ? row.length : index < 3) {
                        return (
                          <li key={index} className="flex gap-1 items-start">
                            <p>{`${index + 1}.`}</p>
                            {list?.is_source_link ? (
                              <a
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                className="break-all"
                                href={list.sources_list.text}
                              >
                                {list.sources_list.text}
                              </a>
                            ) : (
                              <p>{list?.sources_list?.text}</p>
                            )}
                          </li>
                        );
                      }
                    })}
                  </ul>
                  <div className="cursor-pointer">
                    <img
                      className="w-7 mt-4 mx-auto"
                      alt="arrow"
                      src={handleDropDown ? SubtractCircle : addCircle}
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {post?.disclaimer_description?.html && (
            <Disclaimer
              disclaimerDescription={post?.disclaimer_description?.html}
              disclaimerMoreInfoLabel={post?.disclaimer_more_info_label?.text}
              disclaimerMoreInfoContent={post?.disclaimer_more_info_content?.html}
            />
          )}
        </article>
        {post?.show_toc && isBrowser && window.innerWidth >= SCREEN_WIDTH?.SM ? (
          <div className="mx-0 px-6 md:px-0 rounded-lg md:mb-8 md:block hidden">
            <TableofContents
              longToc={post?.long_table_of_content}
              tocs={headings}
              isTableInViewPort={isTableInViewPort}
            />
          </div>
        ) : null}
      </div>
      <div className="mt-16 max-w-6xl md:px-0 px-4 pt-4 mx-auto layout" id="related-articles">
        <h2 className="mb-8  text-gray-300  category-title">Related articles</h2>
        <div className="flex justify-between flex-wrap">
          {post?.blog_floating_related_articles_list?.[0]?.related_aticle?.uid &&
          Object.keys(post?.blog_floating_related_articles_list?.[0]?.related_aticle?.document).length > 0 ? (
            <>
              {post?.blog_floating_related_articles_list?.map((article, index) => {
                return (
                  <div key={index} className="mb-8">
                    <CustomRelatedArticleCard data={article?.related_aticle} />
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {categoryBlogs &&
                categoryBlogs.map((blog, index) => {
                  if (index < 4) {
                    return (
                      <div key={index} className="mb-8">
                        <ArticleCard isFromGuides={true} data={blog} />
                      </div>
                    );
                  }
                })}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

GuidesTemplate.propTypes = {
  articleData: PropTypes.any,
  allPrismicArticleList: PropTypes.any,
  children: PropTypes.any
};

export default GuidesTemplate;
