import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

customizedDialogs.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  maxWidth: PropTypes.any,
  minWidth: PropTypes.any,
  isCloseRequired: PropTypes.bool
};

customizedDialogs.defaultProps = {
  title: '',
  isCloseRequired: true
};

const styles = (theme) => ({
  root: {
    margin: 0,
    zIndex: 99999
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, isCloseRequired, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <>
          {isCloseRequired && (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </>
      ) : null}
    </MuiDialogTitle>
  );
});

function customizedDialogs({
  children,
  openDynamicModal,
  closeDynamicModal,
  maxWidth,
  minWidth,
  title,
  isCloseRequired
}) {
  const [open, setOpen] = React.useState(openDynamicModal);
  const handleClose = () => {
    setOpen(false);
    closeDynamicModal();
  };
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: '0px 32px 32px 32px',
      maxWidth: maxWidth,
      minWidth: minWidth,
      [theme.breakpoints.down('md')]: {
        minWidth: '100%'
      }
    }
  }))(MuiDialogContent);
  const Dialog = withStyles(() => ({
    root: {
      justifyContent: 'end'
    }
  }))(MuiDialog);
  useEffect(() => {
    setOpen(openDynamicModal);
  }, [openDynamicModal]);
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" isCloseRequired={isCloseRequired} onClose={handleClose}>
        <div className="ml-2">{title}</div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default customizedDialogs;
