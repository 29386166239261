import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrorwIcon from '../../../images/icons/down-arrow-gray.svg';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import FaqArticleList from './FaqArticleList';

export default function FaqArticleDropDown({ categoryTitle, faqsList, articleUid }) {
  const [handleDropDown, setHandleDropDown] = useState(false);

  return (
    <div
      className={`
        ${
          handleDropDown ? 'transition-all duration-500' : ' transition-all duration-500'
        } rounded-lg bg-white mb-4 min-w-[340px] md:max-w-[340px] 
      `}
    >
      <div
        className="flex justify-between items-start cursor-pointer"
        onClick={() => setHandleDropDown(!handleDropDown)}
      >
        <MDSubtitleText
          className=" w-10/12 whitespace-pre-line faq-text"
          fontWeight="text-bold"
          title={categoryTitle}
        />
        <div className="cursor-pointer">
          <img
            className={`w-4 mx-auto ${handleDropDown && 'transform rotate-180'}`}
            width="15"
            height="8"
            alt="arrow"
            loading="lazy"
            src={ArrorwIcon}
          />
        </div>
      </div>
      <ul className={handleDropDown ? 'block mt-2 ml-8' : 'hidden'}>
        {faqsList?.map((faqList) => {
          return (
            <FaqArticleList
              key={faqList?.uid}
              faqList={faqList}
              articleUid={articleUid}
              setHandleDropDown={setHandleDropDown}
            />
          );
        })}
      </ul>
    </div>
  );
}

FaqArticleDropDown.propTypes = {
  categoryTitle: PropTypes.string,
  categoryUid: PropTypes.string,
  faqsList: PropTypes.array,
  open: PropTypes.bool,
  articleUid: PropTypes.string,
  articleCategory: PropTypes.string
};
