import React, { useState } from 'react';
import CopyIcon from '.././../../images/icons/inline/copy.inline.svg';
import PropTypes from 'prop-types';
import { TwitterShareButton, XIcon } from 'react-share';
import { MUI_COLORS } from '../../../constants/enum';
import { isBrowser } from '../../../helper/helpers';
import '../../../styles/components/highlightbox.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
export default function HighlightBox({ highlightBoxData }) {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const image = getImage(highlightBoxData.box_icon);
  const RED = 'red';
  const GREEN = 'green';
  const url = isBrowser && window.location.href;
  const parts = url && url?.split('#');
  const modifiedUrl = parts.length > 1 ? parts[0] : url;

  const color = highlightBoxData?.color;
  const getContainerColors = () => {
    if (color === GREEN) {
      return 'border border-green-500 bg-green-200';
    } else if (color === RED) {
      return 'border border-coral-500 bg-salmon-500';
    } else {
      return 'border border-yellow-500 bg-yellow-300';
    }
  };
  const boxColor = () => {
    if (color === GREEN) {
      return MUI_COLORS?.GREEN;
    } else if (color === RED) {
      return MUI_COLORS?.CORAL;
    } else {
      return MUI_COLORS?.BROWN;
    }
  };
  const boxBgColor = () => {
    if (color === GREEN) {
      return MUI_COLORS?.LIGHT_GREEN;
    } else if (color === RED) {
      return MUI_COLORS?.LIGHT_CREAM;
    } else {
      return MUI_COLORS?.PAPAYA_WHIP;
    }
  };

  const copyText = () => {
    const textToCopy = `"${highlightBoxData?.box_title?.text}"` + ` Extracted from Statrys blog at ${modifiedUrl}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Failed to copy text:', error);
      });
  };
  return (
    <div className={`flex flex-col md:flex-row gap-6 p-6 items-start rounded-lg ${getContainerColors()} my-8`}>
      {highlightBoxData?.box_icon && (
        <div>
          <GatsbyImage
            image={image}
            className="w-14 h-14"
            objectFit="contain"
            alt={highlightBoxData?.box_icon?.alt ? highlightBoxData?.box_icon?.alt : 'box icon'}
          />
        </div>
      )}

      <div className="flex flex-col items-end">
        <div
          className={`highLights-content ${highlightBoxData?.color}Color`}
          dangerouslySetInnerHTML={{ __html: highlightBoxData?.box_title?.html }}
        ></div>
        <div className="flex items-center">
          {highlightBoxData?.twitter_share && (
            <TwitterShareButton
              title={`"${highlightBoxData?.box_title?.text}"` + ' Extracted from Statrys blog at'}
              url={modifiedUrl}
            >
              <XIcon size={40} bgStyle={{ fill: boxBgColor() }} iconFillColor={boxColor()} />
            </TwitterShareButton>
          )}
          {highlightBoxData?.copy_text && (
            <div onClick={copyText} className="hover:shadow p-1 rounded cursor-pointer relative">
              <CopyIcon stroke={boxColor()} />
              {tooltipVisible && (
                <div
                  style={{
                    position: 'absolute',
                    backgroundColor: boxColor(),
                    color: MUI_COLORS?.WHITE,
                    padding: '8px',
                    borderRadius: '4px',
                    top: 'calc(100% + 4px)',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 9999
                  }}
                >
                  Text copied!
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
HighlightBox.propTypes = {
  highlightBoxData: PropTypes.object
};
