import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import '../../styles/pages/blogs.scss';
import '../../styles/pages/Blog/header.scss';
import XXSText from '../ui/typography/XXSText';
import { guidesCustomUrl } from '../../helper/helpers';
import ArticleLeftSide from './ArticleLeftSide';
import ArticleTopRightSide from './ArticleTopRightSide';
import ArticleBottomRightSide from './ArticleBottomRightSide';
import SearchInput from '../ui/form/SearchInput';
function Header({ uniqueCategoryBlog, country }) {
  const [handleLink, setHandleLink] = useState(false);
  const startIndex = 1;

  const navigateToSearchResults = (searchTerm) => {
    navigate('/searchGuide', { state: { searchTerm } });
  };

  return (
    <>
      <div className="flex mt-4 md:flex-row flex-col gap-4 justify-between">
        <div className="flex flex-row gap-1 items-center">
          <Link onMouseEnter={() => setHandleLink(true)} onMouseLeave={() => setHandleLink(false)} to="/guides">
            <p
              className={`text-bold text-gray-500 letter-spacing-1 guide  ${
                handleLink ? 'text-coral-500' : 'text-gray-500'
              }`}
            >
              Guides
            </p>
          </Link>
          <div className={`bg-coral-500 h-2 w-2  rounded-full mx-2`} />
          <h1 className="guide text-normal blog-type">{country}</h1>
        </div>
        <SearchInput onSearch={(value) => navigateToSearchResults(value)} />
      </div>
      <div className="flex lg:flex-row md:flex-col flex-col justify-between mt-8 mb-11">
        <ArticleLeftSide articleData={uniqueCategoryBlog} />
        <div className="flex flex-col justify-between lg:mt-0 md:mt-6 mt-6">
          <div>
            <ArticleTopRightSide articleData={uniqueCategoryBlog} />
          </div>
          <div>
            {uniqueCategoryBlog.slice(startIndex + 1, startIndex + 3).map((blog, index) => (
              <>
                <Link to={guidesCustomUrl(blog?.data?.category?.document)}>
                  <XXSText
                    className="mt-6 mb-2"
                    fontWeight="text-bold"
                    textColor="text-coral-500"
                    title={blog?.data?.category?.document?.data?.title?.text}
                  />
                </Link>
                <ArticleBottomRightSide blog={blog} key={index} />
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

Header.propTypes = {
  page: PropTypes.number,
  uniqueCategoryBlog: PropTypes.object,
  blog: PropTypes.array,
  country: PropTypes.string
};

export default Header;
