import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GatsbyImageWrapper from '../GatsByImageWrapper';
import PText from '../typography/PText';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const Toggleable = ({ data }) => {
  const [isToggled, setToggled] = useState(true);
  return (
    <div className="rounded-lg mb-8 bg-white no-underline">
      <div className="flex flex-col gap-4 w-full p-6">
        <div className="flex flex-row gap-4 items-center cursor-pointer" onClick={() => setToggled(!isToggled)}>
          {data?.icon1?.url && (
            <GatsbyImageWrapper
              image={{
                url: data?.icon1?.url,
                width: 32,
                height: 32
              }}
              alt={data?.icon1?.alt}
              loading="lazy"
              className="w-8 h-8"
              objectFit="contain"
            />
          )}
          <PText
            title={data?.heading.text}
            fontWeight="text-bold"
            style={{ lineHeight: '110%', marginBottom: 0 }}
            fontSize="text-xl"
            textColor="text-gray-500"
            className={`${data?.icon1?.url ? 'md:w-[87%] w-[59%]' : 'md:w-[95%] w-[81%]'} `}
          />
          <StaticImage
            src="../../../images/icons/arrowDownWithCircle.svg"
            className={`w-6 h-6 object-contain transition-all delay-75 ease-in-out transform ${
              !isToggled && 'rotate-180'
            }`}
            alt="drop down arrow"
            loading="lazy"
          />
        </div>
        {!isToggled && (
          <Link to={data?.link?.url} target={data?.link?.target} style={{ textDecoration: 'none' }}>
            <PText
              title={data?.desciption.text}
              fontSize="text-lg"
              fontWeight="text-normal"
              textColor="text-gray-500"
              className={`${data?.icon1?.url && 'ml-12'} ${
                data?.icon1?.url ? 'md:w-[87%] w-[59%]' : 'md:w-[95%] w-[81%]'
              }`}
              style={{ lineHeight: '26px', marginBottom: 0, fontWeight: '400' }}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default Toggleable;

Toggleable.propTypes = {
  data: PropTypes.array
};
