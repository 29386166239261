import React, { useEffect, useState } from 'react';
import StatrysLogo from '../../../static/statrys-logo.svg';
import StatrysTalisman from '../../../static/statrys-talisman.svg';
import MenuText from '../ui/typography/MenuText';
import { Link, navigate } from 'gatsby';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import BaseText from '../ui/typography/BaseText';
import { slide as Menu } from 'react-burger-menu';
import PropTypes from 'prop-types';
import NavigationDropDown from './NavigationDropDown';
import ResourcesDropDown from './ResourcesDropDown';
import { getSvgIcon } from '../../helper/svgHelper';
import { MUI_COLORS, SVG_TYPES, PRODUCT_PAGES_URL, EXTERNAL_LINKS } from '../../constants/enum';
import MobileNavigationDropDown from './MobileNavigationDropDown';
import contactUs from '../../data/contact-us.json';
import WhatsAppIcon from '../../images/icons/customSvg/whatsAppIcon';
import ArrowRight from '../../images/icons/arrow-down-dark-menu.svg';
import BackArrow from '../../images/icons/arrow-right.svg';
import MobileAppLogo from '../../images/graphics/Logo/mobile-app-statrys-logo.png';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import Xstext from '../ui/typography/XSText';
import ApkDownloadWrapper from '../Wrappers/ApkDownloadWrapper';
import XXSText from '../ui/typography/XXSText';
import { ENDPOINTS } from '../../service/end-points';
import HongKongFlag from '../../images/icons/hong-kong-sar.png';
import SingaporeFlag from '../../images/icons/singapore.png';
import '../../styles/header-navigation.scss';

const tab1 = 'tab1';
const tab2 = 'tab2';
const tab3 = 'tab3';

function HeaderNavigation({ paymentCard, isDashboardPage }) {
  const [activeContactTab, setActiveContactTab] = useState();
  const [whichMenu, setWhichMenu] = useState('');
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLoginClicked, setLoginClicked] = useState(false);
  const onLogout = () => {
    sessionStorage.clear();
    navigate(ENDPOINTS?.LOGOUT);
  };

  const productMenuItems = [
    { pageName: 'Hong Kong Business Account', icon: getSvgIcon(SVG_TYPES.STATRYS_LOGO), to: '/business-account' },
    {
      pageName: 'International and local payments',
      icon: getSvgIcon(SVG_TYPES.PAYMENTS_BILLS),
      to: '/international-and-local-payments'
    },
    { pageName: 'Payment Cards', icon: getSvgIcon(SVG_TYPES.CREDIT_CARD), to: '/uk/payment-card' },
    { pageName: 'Foreign Exchange', icon: getSvgIcon(SVG_TYPES.CURRENCY_EURO_DOLLAR_EXCHANGE), to: '/forex' },
    { pageName: 'Get rewarded', icon: getSvgIcon(SVG_TYPES.GET_REWARDED), to: '/get-rewarded' },
    { pageName: 'Pricing', icon: getSvgIcon(SVG_TYPES.TAG_DOLLAR), to: '/pricing' }
  ];
  const companyCreationMenuItems = [
    { pageName: 'Hong Kong', icon: HongKongFlag, to: ENDPOINTS?.HONG_KONG_LANDING_PAGE },
    {
      pageName: 'Singapore',
      icon: SingaporeFlag,
      to: ENDPOINTS?.SINGAPORE_LANDING_PAGE
    }
  ];

  const resourceMenuItems = [
    { pageName: 'Business Guides', icon: getSvgIcon(SVG_TYPES.BOOK_OPEN), to: '/guides' },
    { pageName: 'Blog', icon: getSvgIcon(SVG_TYPES.STATRYS_LOGO), to: '/blog' },
    {
      pageName: 'Business Account Reviews',
      icon: getSvgIcon(SVG_TYPES.BOOK_OPEN_BOOKMARK),
      to: `/reviews/business-bank-accounts`
    },
    { pageName: 'Comparisons', icon: getSvgIcon(SVG_TYPES.COMPARISON), to: '/comparisons' },
    {
      pageName: 'Company Secretary Reviews',
      icon: getSvgIcon(SVG_TYPES.RATING_STARS),
      to: `${PRODUCT_PAGES_URL.COMPANY_SECRETARY_URL}`
    },
    { pageName: 'Whitepapers & E-Books', icon: getSvgIcon(SVG_TYPES.BOOK_LIBRARY), to: '/resources/whitepapers' },
    { pageName: 'Industry Newsletters', icon: getSvgIcon(SVG_TYPES.SINGLE_MAN_MAIL), to: '/resources/newsletters' },
    {
      pageName: 'PayPal Fee Calculator',
      icon: getSvgIcon(SVG_TYPES.PERFORMANCE_GRAPH),
      to: `/tools/paypal-fee-calculator`
    },
    {
      pageName: 'Stripe Fee Calculator',
      icon: getSvgIcon(SVG_TYPES.ACCOUNTING_CALCULATOR),
      to: `/tools/stripe-fee-calculator`
    },
    {
      pageName: 'Invoice Generator',
      icon: getSvgIcon(SVG_TYPES.INVOICE_GENERATOR),
      to: `/tools/invoice-generator`,
      isNewMenuItem: true
    }
  ];

  const companyMenuItems = [
    { pageName: 'About Statrys', icon: getSvgIcon(SVG_TYPES.STATRYS_LOGO), to: '/about-us' },
    { pageName: 'Customer reviews', icon: getSvgIcon(SVG_TYPES.NEWS_PAPER_READ), to: '/reviews' },
    { pageName: 'FAQs', icon: getSvgIcon(SVG_TYPES.QUESTION_CIRCLE), to: '/faq' },
    { pageName: 'Partner Programs', icon: getSvgIcon(SVG_TYPES.BUSINESS_PARTNER), to: '/partners' },
    { pageName: 'Contact us', icon: getSvgIcon(SVG_TYPES.CONTACT_US), to: '/contact' }
  ];
  const clickedOnMenuItem = (menu) => {
    if (whichMenu === menu) {
      if (!isDropDownOpen) {
        setWhichMenu(menu);
        setIsDropDownOpen(true);
      } else {
        setIsDropDownOpen(false);
      }
    } else {
      setWhichMenu(menu);
      setIsDropDownOpen(true);
    }
  };

  const moveToMobileApp = () => {
    setLoginClicked(true);
  };
  useEffect(() => {
    setLoginClicked(false);
  }, []);

  const redirecToMenu = () => {
    setLoginClicked(false);
  };
  return (
    <div className="md:static relative flex header-navigation md:gap-0 gap-4 max-w-6xl lg:px-8 xl:px-0 mx-auto items-center justify-between">
      {isDashboardPage ? null : (
        <>
          <div className="handburger-menu">
            {!isLoginClicked ? (
              <Menu>
                <div>
                  <div className="sticky top-0 bg-white h-16">
                    <div className="flex flex-row justify-between ml-16 items-center pt-4 ">
                      {isDashboardPage ? (
                        <Link to="/">
                          <img src="/statrys-talisman.svg" loading="eager" alt="Statrys Logo" />
                        </Link>
                      ) : (
                        <>
                          <Link to="/">
                            <img src="/statrys-logo.svg" loading="eager" alt="Statrys logo" />
                          </Link>
                          {paymentCard ? (
                            <BaseText className="pt-2" fontWeight="text-bold hover:no-underline" title="UK" />
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="mt-6 expand-section ">
                    <div
                      className="flex flex-row justify-between border-t border-gray-400 w-full px-4"
                      onClick={() => clickedOnMenuItem('product')}
                    >
                      <BaseText title="Business account" fontWeight="text-semibold" className="py-6" />
                      <img src={ArrowRight} width="16" height="16" alt="drop down arrow" />
                    </div>
                  </div>
                  {whichMenu === 'product' && isDropDownOpen ? (
                    <MobileNavigationDropDown menuItems={productMenuItems} />
                  ) : null}

                  <div className="expand-section ">
                    <div
                      className="flex flex-row justify-between border-t border-gray-400 w-full px-4"
                      onClick={() => clickedOnMenuItem('company-creation')}
                    >
                      <BaseText title="Company creation" fontWeight="text-semibold" className="py-6" />
                      <img src={ArrowRight} width="16" height="16" alt="drop down arrow" />
                    </div>
                  </div>
                  {whichMenu === 'company-creation' && isDropDownOpen ? (
                    <MobileNavigationDropDown menuItems={companyCreationMenuItems} />
                  ) : null}
                  <div className="expand-section">
                    <Link id="Invoicing" to={ENDPOINTS?.INVOICES} className="hover:no-underline menu-item w-full">
                      <div className="flex flex-row justify-between border-t border-gray-400 w-full px-4">
                        <BaseText title="Invoicing" fontWeight="text-semibold" className="py-6" />
                      </div>
                    </Link>
                  </div>
                  <div className="expand-section">
                    <div
                      className="flex flex-row justify-between border-t border-gray-400 w-full px-4"
                      onClick={() => clickedOnMenuItem('resources')}
                    >
                      <BaseText title="Resources" fontWeight="text-semibold" className="py-6" />
                      <img src={ArrowRight} width="16" height="16" alt="drop down arrow" />
                    </div>
                  </div>
                  {whichMenu === 'resources' && isDropDownOpen ? (
                    <MobileNavigationDropDown menuItems={resourceMenuItems} />
                  ) : null}
                  <div className="expand-section">
                    <div
                      className="flex flex-row justify-between border-t border-gray-400 w-full px-4"
                      onClick={() => clickedOnMenuItem('about')}
                    >
                      <BaseText title="About" fontWeight="text-semibold" className="py-6" />
                      <img src={ArrowRight} width="16" height="16" alt="drop down arrow" />
                    </div>
                  </div>
                  {whichMenu === 'about' && isDropDownOpen ? (
                    <MobileNavigationDropDown menuItems={companyMenuItems} />
                  ) : null}
                  <div className="expand-section">
                    <div className="flex flex-row justify-between border-t border-gray-400 w-full px-4">
                      <BaseText title="Contact us" fontWeight="text-semibold" className="py-6" />
                    </div>
                  </div>
                  <div className="contact-us-container mx-4 mt-2 rounded-lg border border-gray-400 items-center cursor-pointer ">
                    <div
                      className={`${
                        activeContactTab === tab1 && 'bg-coral-500'
                      } contact-us-icons-container rounded-l-lg py-3 border-r border-gray-400 flex`}
                      onClick={() => {
                        navigate('/contact'), setActiveContactTab(tab1);
                      }}
                    >
                      {getSvgIcon(
                        SVG_TYPES.SEND_EMAIL,
                        activeContactTab === tab1 ? MUI_COLORS.WHITE : MUI_COLORS.CORAL
                      )}
                    </div>
                    <a
                      onClick={() => setActiveContactTab(tab2)}
                      className={`${
                        activeContactTab === tab2 && 'bg-coral-500'
                      } border-r contact-us-icons-container py-3 border-gray-400 flex`}
                      href={`tel:${contactUs.phone}`}
                    >
                      {getSvgIcon(
                        SVG_TYPES.CONTACT_PHONE,
                        activeContactTab === tab2 ? MUI_COLORS.WHITE : MUI_COLORS.CORAL
                      )}
                    </a>
                    <a
                      onClick={() => setActiveContactTab(tab3)}
                      className={`${
                        activeContactTab === tab3 && 'bg-coral-500'
                      } contact-us-icons-container py-3 rounded-r-lg border-gray-400 flex`}
                      target="_blank"
                      rel="noreferrer"
                      href={contactUs.whatsapp_link}
                    >
                      <WhatsAppIcon
                        fill={activeContactTab === tab3 ? MUI_COLORS.WHITE : MUI_COLORS.CORAL}
                        stroke={activeContactTab === tab3 ? MUI_COLORS.WHITE : MUI_COLORS.WHATS_APP_GREEN}
                      />
                    </a>
                  </div>
                  <div className="absolute bottom-0 bg-white mobile-navigation-bottom-btn-container">
                    <div className="flex flex-row items-center justify-center h-full w-full gap gap-4">
                      <PrimaryButton
                        urlLink="#"
                        className="signup-btn mobile-view-btn"
                        id="navigation-statrys-login"
                        bgColor="bg-white"
                        color="text-gary-500"
                        caption="Login"
                        onClick={moveToMobileApp}
                      />
                      <PrimaryButton
                        className="signup-btn mobile-view-btn"
                        id="nav-statrys-signup"
                        urlLink={process.env.SIGN_UP}
                        color="text-white"
                        caption="Open account"
                      />
                    </div>
                  </div>
                </div>
              </Menu>
            ) : (
              <Menu crossButtonClassName={'custom-cross-btn'}>
                <div className="mobile-app-download-menu-container">
                  <div className="flex flex-row gap gap-4 mobile-app-download-menu p-4">
                    <img
                      src={BackArrow}
                      alt="back arrow"
                      width="24"
                      height="24"
                      loading="lazy"
                      onClick={redirecToMenu}
                    />
                    <Link to="/">
                      <img src="/statrys-logo.svg" loading="eager" alt="Statrys logo" />
                    </Link>
                  </div>
                  <div className="bg-customBg-lightGray flex flex-col items-center">
                    <img
                      src={MobileAppLogo}
                      alt="mobile-app-logo"
                      width="103"
                      height="103"
                      loading="lazy"
                      className="mt-6"
                    />
                    <MDSubtitleText title="Statrys Mobile App" fontWeight="text-bold" className="mt-4" />
                    <MDSubtitleText
                      title="Your business account on the palm of your hand."
                      className="mt-2 px-20"
                      textAlign="text-center"
                    />
                    <Xstext
                      title="Download"
                      fontWeight="text-bold"
                      className="mt-6 download-text"
                      textAlign="text-center"
                    />
                    <div className="flex md:flex-row flex-col gap-4 justify-between items-center mt-2">
                      <a href={EXTERNAL_LINKS.APPLE_STORE} id="login-mobile-appstore">
                        <img src="../../../app-store.svg" width="152" height="40" alt="app-store" />
                      </a>
                      <a href={EXTERNAL_LINKS.GOOGLE_STORE} id="login-mobile-android">
                        <img src="../../../play-store.svg" width="152" height="40" alt="play-store" />
                      </a>
                      <ApkDownloadWrapper>
                        <div className="cursor-pointer" id="login-mobile-sdk">
                          <img src="../../../apk-download.svg" width="152" height="40" alt="apk-download" />
                        </div>
                      </ApkDownloadWrapper>
                    </div>
                    <Link to={process.env.LOGIN} className="mt-6">
                      <XXSText
                        title="Login to my account here"
                        fontWeight="text-bold"
                        textColor="text-coral-500"
                        textAlign="text-center"
                      />
                    </Link>
                  </div>
                </div>
              </Menu>
            )}
          </div>
        </>
      )}

      <div className="md:w-auto w-32">
        <Link className="flex hover:no-underline " to="/">
          {isDashboardPage ? (
            <img src={StatrysTalisman} loading="eager" alt="Statrys Logo" />
          ) : (
            <>
              <img src={StatrysLogo} loading="eager" alt="Statrys Logo" />
              {paymentCard ? <BaseText className="pt-1" fontWeight="text-bold" title="UK" /> : null}
            </>
          )}
        </Link>
      </div>

      {isDashboardPage ? (
        <button onClick={onLogout} className="ml-auto">
          Log out
        </button>
      ) : (
        <>
          <div className="ml-8 items-center navigation-items-container">
            <div className="menu-text-container navigation-items-container">
              <div className="item-menu item-menu-dropdown dropdown">
                <MenuText
                  title="Business account"
                  withDropDown={true}
                  linkClassName="menu-text-link flex"
                  imgClassName="menu-text-image"
                />
                <NavigationDropDown menuItems={productMenuItems} needSeparator={true} separtorPosition={4} />
              </div>
              <div className="dropdown item-menu item-menu-dropdown">
                <MenuText
                  title="Company creation"
                  withDropDown={true}
                  linkClassName="menu-text-link flex"
                  imgClassName="menu-text-image"
                />
                <NavigationDropDown menuItems={companyCreationMenuItems} />
              </div>
              <div className="dropdown">
                <MenuText
                  title="Invoicing"
                  withDropDown={true}
                  linkClassName="menu-text-link"
                  urlLink={ENDPOINTS.INVOICES}
                  imgClassName="hidden"
                />
              </div>

              <div className="item-menu-dropdown item-menu dropdown">
                <MenuText
                  title="Resources"
                  linkClassName="menu-text-link flex"
                  imgClassName="menu-text-image"
                  withDropDown={true}
                />
                <ResourcesDropDown menuItems={resourceMenuItems} needSeparator={true} separtorPosition={5} />
              </div>
              <div className="item-menu-dropdown item-menu dropdown">
                <MenuText
                  title="About"
                  linkClassName="menu-text-link flex"
                  imgClassName="menu-text-image"
                  withDropDown={true}
                />
                <NavigationDropDown menuItems={companyMenuItems} needSeparator={true} separtorPosition={4} />
              </div>
            </div>
          </div>
          <div className="ml-auto flex gap-4 items-center">
            <div className="item-menu sm:block hidden">
              <MenuText title="Login" urlLink={process.env.LOGIN} id="nav-login" />
            </div>
            <div className="sm:flex hidden relative z-10">
              <PrimaryButton
                className="signup-btn"
                id="nav-signup"
                urlLink={process.env.SIGN_UP}
                color="text-white"
                caption="Get Started"
              />
            </div>
            <div className="sm:hidden block">
              <PrimaryButton className="signup-btn" urlLink={process.env.SIGN_UP} caption="Sign Up" />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

HeaderNavigation.propTypes = {
  paymentCard: PropTypes.bool,
  isDashboardPage: PropTypes.bool
};

HeaderNavigation.defaultProps = {
  isDashboardPage: false
};

export default HeaderNavigation;
